import React, {
  useState,
  useEffect,
  createContext,
  useMemo,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import { BrowserClient } from '@amplitude/analytics-types';
import { environment } from '@helpers/environment';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import doOnce from '@utils/doOnce';
import useCookieConsent from '@hooks/useCookieConsent';
import useCurrentUser from '@hooks/useCurrentUser';
import {
  getAmplitudeSession,
  getAmplitudeDeviceID,
  setAmplitudeSession,
  setAmplitudeDeviceID,
} from '@utils/amplitudeSession';
import * as amplitude from '@amplitude/analytics-browser';

const amplitudeKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '';
const deploymentKey: string = process.env.NEXT_PUBLIC_AMPLITUDE_DEPLOYMENT_KEY || '';
const amplitudeServerUrl = process.env.NEXT_PUBLIC_AMPLITUDE_SERVER_URL || '';

type TrackingOptions = {
  withURLandTitle?: boolean;
};

interface ContextValue {
  setUserId: (id?: string) => void;
  track: (eventName: string, data: any, options?: TrackingOptions) => Promise<any>;
  amplitude: BrowserClient | null;
  amplitudeExperiment: ExperimentClient | null;
}

const EventsContext = createContext<ContextValue>({
  amplitude: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserId: (id?: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track: (eventName: string, data: any, options = {}): Promise<any> =>
    // Default implementation: do nothing
    Promise.resolve(),
  amplitudeExperiment: null,
});

interface EventsProviderProps {
  children: ReactNode;
}

const eventsInProgress: string[] = [];

export function EventsProvider({ children }: EventsProviderProps) {
  const [amplitudeInstance, setAmplitudeInstance] = useState<BrowserClient | null>(null);
  const [amplitudeExperimentInstance, setAmplitudeExperimentInstance] =
    useState<ExperimentClient | null>(null);
  const currentUser = useCurrentUser();
  const { allowCookies } = useCookieConsent();

  // Initialize Amplitude + Experiment
  useEffect(() => {
    let pollAmplitudeSessionId: any = null;
    const amplitudeSessionId = getAmplitudeSession();
    const amplitudeDeviceID = getAmplitudeDeviceID();

    const amplitudeOptions: any = {
      ...(environment.isProduction ? {} : { logLevel: amplitude.Types.LogLevel.Warn }),
      ...(amplitudeSessionId ? { sessionId: amplitudeSessionId } : {}),
      ...(amplitudeDeviceID ? { deviceId: amplitudeDeviceID } : {}),
      ...(amplitudeServerUrl ? { serverUrl: amplitudeServerUrl } : {}),
      defaultTracking: {
        attribution: {
          excludeReferrers: [/resortpass\.com$/, 'accounts.google.com'],
        },
      },
    };

    const initAmplitudeSDK = doOnce(async () => {
      amplitude.init(amplitudeKey, amplitudeOptions);

      // setting custom user property
      const deviceCategory = window.innerWidth < 850 ? 'mobile' : 'desktop';
      const identifyEvent = new amplitude.Identify();
      // Manually set user properties
      identifyEvent.set('device_category', deviceCategory);
      identifyEvent.set('is_logged_in', currentUser.valueOr(null) !== null);
      amplitude.identify(identifyEvent);

      // save instance of amplitude
      setAmplitudeInstance(amplitude);

      // Create Amplitude Proxy for Intercepting Events
      // (Used to capture email sent via digioh)
      if (Proxy && Reflect) {
        const amplitudeHandler = {
          get(target: any, prop: string, receiver: any) {
            if (prop === 'track') {
              return (
                eventName: string,
                eventProperties: Record<string, any> | undefined,
                ...args: any[]
              ) => {
                if (eventName === 'email_captured' && eventProperties?.email) {
                  const idEvent = new amplitude.Identify();
                  idEvent.set('email', eventProperties.email);
                  amplitude.identify(idEvent);
                }
                target[prop].call(amplitude, eventName, eventProperties, ...args);
              };
            }
            return Reflect.get(target, prop, receiver);
          },
        };
        const proxy = new Proxy(amplitude, amplitudeHandler);
        (window as any).amplitude = proxy;
      } else {
        (window as any).amplitude = amplitude;
      }

      // Polling amplitude session id... since this isn't instant from testing
      if (pollAmplitudeSessionId) clearInterval(pollAmplitudeSessionId);
      pollAmplitudeSessionId = setInterval(() => {
        const sessionId = amplitude.getSessionId();
        const deviceID = amplitude.getDeviceId();
        if (sessionId && deviceID) {
          setAmplitudeSession(sessionId);
          setAmplitudeDeviceID(deviceID);
          clearInterval(pollAmplitudeSessionId);
        }
      }, 250);

      // Initialize Experiment
      const experiment = Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
        automaticFetchOnAmplitudeIdentityChange: true,
      });
      if (experiment) {
        await experiment.start();
        setAmplitudeExperimentInstance(experiment);
      }

      return () => clearInterval(pollAmplitudeSessionId);
    });

    if (allowCookies) {
      initAmplitudeSDK();
    }
  }, [setAmplitudeInstance, setAmplitudeExperimentInstance, allowCookies, currentUser]);

  // -------------------------------------------------------------------
  // Automatically Set UserId when a User Logs in ----------------------
  // -------------------------------------------------------------------
  const setUserId = useCallback(
    (id?: string) => {
      if (amplitudeInstance) {
        amplitudeInstance.setUserId(id);
      }
    },
    [amplitudeInstance],
  );

  const setUserEmail = useCallback(
    (email?: string) => {
      if (amplitudeInstance && email) {
        const id = new amplitude.Identify();
        id.set('email', email);
        amplitudeInstance.identify(id);
      }
    },
    [amplitudeInstance],
  );

  useEffect(() => {
    const userId = currentUser.map((u) => u.id).valueOr(null);
    const userEmail = currentUser.map((u) => u.email).valueOr(null);
    setUserId(userId);
    setUserEmail(userEmail);
  }, [setUserId, setUserEmail, currentUser]);

  const track = useCallback(
    (eventName: string, data: any, options: TrackingOptions = {}) => {
      if (environment.isDevelopment) {
        // eslint-disable-next-line no-console
        console.log(`eventName: ${eventName}, properties: `, data, 'options:', options);
        return Promise.resolve();
      }

      // Send the event from staging or from production if the event is not in progress
      if (
        environment.isStaging ||
        (environment.isProduction && !eventsInProgress.includes(eventName))
      ) {
        if (amplitudeInstance) {
          const trackingData: any = { ...data };
          if (options.withURLandTitle) {
            trackingData.page_title = document.title;
            trackingData.page_url = window.location.href;
          }
          return amplitudeInstance.track(eventName, trackingData).promise;
        }
      }

      return Promise.resolve();
    },
    [amplitudeInstance],
  );

  const value = useMemo(
    () => ({
      track,
      setUserId,
      amplitude: amplitudeInstance,
      amplitudeExperiment: amplitudeExperimentInstance,
    }),

    [track, setUserId, amplitudeInstance, amplitudeExperimentInstance],
  );

  return <EventsContext.Provider value={value}>{children}</EventsContext.Provider>;
}

const useEvents = () => useContext(EventsContext);

export { useEvents };
